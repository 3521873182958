

import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Parents = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <h3>Parent's Seminar</h3>
<em>"Just How Am I Supposed to Answer That?"</em><br/>
<p>Practical Advice for the Tough Questions Teenagers Ask (or wish they could)<br/><br/>
In his national lecture tours, Rabbi Fine has encountered countless teenagers. These teens consistently tell him how they wish their parents would give them specific advice. They really do want help dealing with life’s moral and ethical dilemmas. Rabbi Fine empowers parents, encouraging them to take a positive and proactive stand on the issues that trouble today’s youth—sexuality, alcohol and drug abuse, depression, suicide, spirituality, and more. This seminar will give parents the direction they need to engage their children in meaningful conversation through positive, thoughtful, and straight forward dialogue. Such dialogue can strengthen a child’s character along with helping parents discover and reinforce their own deeply held views.
</p>
    </Layout>
  )
}

export default Parents

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`